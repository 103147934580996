.main_view_main_container {
  padding-top: 100px;
}

@media(max-width: 600px) {
  .main_view_main_container {
    padding-top: 0;
  }
}

.text_fields_full {
  width: 100%;
}
.base_modal_component_container {
  display: block;
  width: max-content;
  height: max-content;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow: auto !important;

  .close_button {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 100000;
  }

  .base_edit_content_container {
    width: 600px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
  }

  .field {
    margin-top: 20px;
    width: 100%;
  }
}
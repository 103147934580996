.authorization_main_container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  z-index: 99999999;

  .auth_inner_container {
    width: 300px;
    max-width: 100%;
    height: max-content;
    max-height: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .one_element {
      margin-top: 20px;
      width: 100%;
    }
  }
}
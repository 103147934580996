.container_modal_about_product {
  width: 1100px;
  height: max-content;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 2px solid black;
  border-radius: 15px;

  @media (max-width: 1100px) {
    width: max-content !important;
  }

  .container_about_product {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }

    .image_product {
      width: 400px;
      max-height: 400px;
      max-width: 100%;
      overflow: hidden;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .description_product {
      margin-left: 20px;
      flex: 1;

      .head {
        font-weight: bold;
        font-size: 24px;
      }

      span {
        display: block;
        font-size: 16px;
        margin-top: 10px;
      }

      .categories_product_container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  .container_points_of_sale {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;

    .container_product_in_point_of_sale {
      width: 250px;
      min-width: 250px;
      height: 200px;
      min-height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 10px;
      padding: 10px;
      position: relative;

      .container_preview_map {
        display: block;
        position: absolute;
        right: 0;
        top: 0;

        span {
          margin-top: 0;
        }
      }

      span {
        margin-top: 10px;

        b {
          margin-left: 7px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding: 0;

    .container_points_of_sale {
      flex-direction: column;
      padding: 0;

      .container_product_in_point_of_sale {
        min-width: 100px;
        width: 90%;
      }
    }
  }
}
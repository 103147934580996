.about_main_container {
  max-width: 85%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0 auto;

  .headline {
    font-weight: bolder;
  }
}
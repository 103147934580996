.top_bar_main_container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 1px 24px 0 rgba(0,0,0,0.14);
  background: white;
  overflow: hidden;

  .link_container {
    text-decoration: none;

    .button {

    }
  }

  .container_advantages {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media(max-width: 1000px) {
  .top_bar_main_container {
    .container_advantages {
      display: none;
    }
  }
}

@media(max-width: 600px) {
  .top_bar_main_container {
    position: relative;
    flex-direction: column;
    height: max-content;
    margin-bottom: 30px;
  }
}
.navigations_main_container {

  .app_bar {
    .toolbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left_tab, .right_tab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .content_pages_container {

  }
}
.container_main_products {
  display: flex;
  flex-direction: row;

  .container_options_sampling {
    width: 300px;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container_pagination_and_cards_product {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;

    .container_cards_product {
      flex: 1;
      height: max-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @media (max-width: 550px) {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 1000px) {
  .container_main_products {
    flex-direction: column;

    .container_options_sampling {
      width: 100%;
    }
  }
}
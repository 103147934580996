.main_container_pages_list {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .link_button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    text-decoration: none;
    font-weight: bolder;
    color: blue;

    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }
}
.schedules_page_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.schedule_card_cursor_pointer {
  &:hover {
    cursor: pointer;
  }
}


.schedule_card_container {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 15px;
  text-align: left;
  font-size: 14px;
  position: relative;
  overflow: hidden;

  .container_hover_edit {
    position: absolute;
    background: rgba(255,255,255,0.9);
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    transition: bottom 0.5s;

    .edit_icon {
      position: absolute;
      width: 65px;
      height: 65px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .container_hover_edit {
      cursor: pointer;
      bottom: 0;
    }
  }

  .icon_add {
    width: 65px;
    height: 65px;
    margin: auto;
  }
}

.schedule_modal_edit {
  max-width: 600px;
  max-height: 100%;
  padding: 0 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto !important;

  .close_button {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .field {
    margin-top: 10px;
    width: 100%;
  }
}
.bottom_bar_main_container {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #E0E0E0;
  overflow: hidden;

  .main_center_content_container {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
  }

  .copyright_container {
    width: 300px;
    height: 100%;
    background: #551001;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
  }
}

@media(max-width: 800px) {
  .bottom_bar_main_container {
    position: relative;
    flex-direction: column;
    height: max-content;
    margin-top: 30px;

    .main_center_content_container {
      flex-direction: column;
    }

    .copyright_container {
      width: 100%;
    }
  }
}
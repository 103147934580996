.product_card {
  width: 200px;
  height: 250px;
  margin: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transform: translate(0,0);
  transition: all 0.5s;

  .product_card_image_container {
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .icon_add {
    width: 65px;
    height: 65px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .product_info_container {
    padding: 0 10px 10px 10px;
    width: 180px;
    height: max-content;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0, calc(100% - 50px));
    background: white;
    transition: all 0.5s;

    .head {
      margin: 15px 0;
      font-weight: bold;
      font-size: 16px;
      transition: all 0.5s;
      text-align: center;
      white-space: nowrap;
    }

    span {
      display: block;
      font-size: 12px;
      text-align: left;
      margin-top: 5px;
    }
  }

  &:hover {
    box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.31);
    transform: translate(0,-5px);

    .product_info_container {
      transform: translate(0, 0);

      .head {
        margin: 5px 0 0 0;
      }
    }
  }
}

.product_category_container {
  width: max-content;
  height: max-content;
  font-weight: bold;
  padding: 5px;
  margin: 5px;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  white-space: nowrap;
}

.alternate_delete_product_category_container {
  &:hover {
    background: rgba(255,0,0,0.2);;
    cursor: pointer;
  }
}
.contacts_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  text-align: left;
  margin: 0 auto;

  .card {
    width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    overflow: hidden;

    table {
      width: 100%;

      tr {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
      }

      details {
        summary {
          cursor: pointer;
          outline: none;
          border: none;
        }
      }
    }
  }
}